const ProductsCard = (props) => {
  return (
    <div class="relative group">
      <div class="overflow-hidden aspect-w-1 aspect-h-1">
        <img
          class="object-cover w-full h-full transition-all duration-300 group-hover:scale-125"
          src={props.img}
          alt=""
        />
      </div>

      <div class="flex items-start justify-between mt-4 space-x-4">
        <div>
          <h3 class="text-xs font-bold text-gray-900 sm:text-sm md:text-lg">
            <a href="#" title="">
              {props.title}
              <span class="absolute inset-0" aria-hidden="true"></span>
            </a>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default ProductsCard;
