import Navbar4 from "../components/navbar4";
import VisitingCards from "../images/VisitingCards.jpg";
import Banner from "../images/Banner.jpg";
import Handbill from "../images/handbill.jpg";
import Mug from "../images/mug.jpg";
import Tshirt from "../images/Tshirt.jpg";
import IDCard from "../images/IDCard.jpg";
import Vinyl from "../images/Vinyl.jpg";
import Light from "../images/light.jpg";
import "./home.css";
import Contact4 from "../components/contact4";
import ProductsCard from "../components/products";
import ScrollAnimation from "react-animate-on-scroll";
export default function Products(props) {
  return (
    <div className="home-container">
      <div className="home-navbar1">
        <Navbar4></Navbar4>
      </div>
      <section class="py-12 bg-white sm:py-16 lg:py-20 section">
        <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div class="max-w-xl mx-auto text-center">
            <h2 class="text-2xl font-bold text-gray-900 sm:text-5xl">
              Our featured products
            </h2>
            <p class="mt-4 text-base font-normal  text-gray-600">
              Discover our top products tailored to elevate your brand presence.
              From banners to billboards, we offer impactful advertising
              solutions crafted for maximum visibility. Find your perfect
              advertising solution today.
            </p>
          </div>

          <div class="grid grid-cols-2 gap-6 mt-10 lg:mt-16 lg:gap-4 lg:grid-cols-4">
            <ProductsCard title={"Banner Printing"} img={Banner}></ProductsCard>
            <ProductsCard
              title={"Light Board Printing"}
              img={Light}
            ></ProductsCard>
            <ProductsCard title={"Vinyl Printing"} img={Vinyl}></ProductsCard>
            <ProductsCard
              title={"Hand Bill Printing"}
              img={Handbill}
            ></ProductsCard>
            <ProductsCard title={"Mug Printing"} img={Mug}></ProductsCard>
            <ProductsCard
              title={"Visiting Card Printing"}
              img={VisitingCards}
            ></ProductsCard>
            <ProductsCard
              title={"T-shirt Printing"}
              img={Tshirt}
            ></ProductsCard>
            <ProductsCard
              title={"ID Card Printing"}
              img={IDCard}
            ></ProductsCard>
          </div>
        </div>
      </section>
      <ScrollAnimation animateIn="fadeIn" >
      <div className="home-contact4">
        <Contact4></Contact4>
      </div>
      </ScrollAnimation>
    </div>
  );
}
