import FeatureCard from "../components/feature-card";
import Navbar4 from "../components/navbar4";
import Contact4 from "../components/contact4";
import { MdDirectionsRailway } from "react-icons/md";
import { FaBell } from "react-icons/fa6";
import { FaBus } from "react-icons/fa";
import { FaShuttleVan } from "react-icons/fa";
import { MdMonitor } from "react-icons/md";
import { FaTrainSubway } from "react-icons/fa6";
import { FaNewspaper } from "react-icons/fa6";
import { IoMdAirplane } from "react-icons/io";
import { MdLocalMall } from "react-icons/md";
import { HiSpeakerphone } from "react-icons/hi";
import { GiBusStop } from "react-icons/gi";
import { GiStreetLight } from "react-icons/gi";
import Image from "../images/Advertising.jpg";
import RailwayGentry from "../images/RailwayGentry.jpg";
import ArwalBack from "../images/ArwalBack.jpg";
import ArwalFront from "../images/ArwalFront.jpg";
import StationRoad from "../images/StationRoad.jpg";
import "./services.css";
import ScrollAnimation from "react-animate-on-scroll";
export default function Services() {
const arr=  [
    {
      name: "Arwal More (Railway Gentry)",
      image: RailwayGentry
    },
    {
      name: "Station Road(25x20)",
      image: StationRoad
    },
    {
      name: "Arwal More (30x22)",
      image: ArwalFront
    },
    {
      name: "Arwal More Back Side(10x22)",
      image: ArwalBack
    }
  ]
  
  return (
    <>
      <Navbar4 />
      <div className="features18-layout349 thq-section-padding bg-[#f3f4f6] animate-fade-up ">
        <div className="thq-section-max-width pb-10 ">
          <div className="features18-content ">
            <div className="features18-section-title w-full sm:w-1/2 animate-fade-right">
              <span className="thq-body-small">
                Empower Your Brand's Reach with Dynamic Advertising Solutions
              </span>
              <div className="features18-content1">
                <h2 className="thq-heading-2 text-3xl">Services</h2>
                <p className="thq-body-large">
                  Maximize your brand's impact with our advertising services:
                  hoardings, visiting cards, light boards, prachar
                  (advertisement), digital prints, and handbills.
                </p>
                <p className="thq-body-large">
                  Our commitment is to deliver ad campaigns that not only serve
                  your purpose but also leave a lasting impression, turning even
                  your toughest competitors into loyal advocates. Renowned as
                  one of the top 10 outdoor advertising agencies in Bihar, and
                  proudly holding the title of No.1 in Jehanabad, Ritesh Flex
                  Printing guarantees excellence in service.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <h2 className="thq-heading-2 text-3xl mb-2">Services we provide</h2>
        <div className=" animate-flip-up w-full flex-wrap bg-orange-400 p-2 sm:p-5 rounded-xl flex flex-col justify-center content-center items-center sm:flex-row md:justify-between mb-20 gap-7">
          <FeatureCard title={"Railway Advertising"}>
            <MdDirectionsRailway size={60} />
          </FeatureCard>
          <FeatureCard title={"Bus Advertising"}>
            <FaBus size={60} />
          </FeatureCard>
          <FeatureCard title={"Railway Jingle Advertising"}>
            <FaBell size={60} />
          </FeatureCard>
          <FeatureCard title={"Van Advertisiing"}>
            <FaShuttleVan size={60} />
          </FeatureCard>
          <FeatureCard title={"TV Advertisiing"}>
            <MdMonitor size={60} />
          </FeatureCard>
          <FeatureCard title={"Metro Advertisiing"}>
            <FaTrainSubway size={60} />
          </FeatureCard>
          <FeatureCard title={"Newspaper Advertisiing"}>
            <FaNewspaper size={60} />
          </FeatureCard>
          <FeatureCard title={"Airport Advertisiing"}>
            <IoMdAirplane size={60} />
          </FeatureCard>
          <FeatureCard title={"Mall Advertisiing"}>
            <MdLocalMall size={60} />
          </FeatureCard>
          <FeatureCard title={"Digital Marketing"}>
            <HiSpeakerphone size={60} />
          </FeatureCard>
          <FeatureCard title={"Bus Shelter Advertising"}>
            <GiBusStop size={60} />
          </FeatureCard>
          <FeatureCard title={"Street Pole Advertising"}>
            <GiStreetLight size={60} />
          </FeatureCard>
        </div>
        <h2 className="thq-heading-2 text-3xl mb-2">Prime Locations</h2>
        <div>
        <ScrollAnimation animateIn="lightSpeedInLeft" >
        <div  className="flex flex-col md:flex-row flex-wrap gap-5 over items-center content-center justify-center">

        {arr.map((item) => {
          return(
            
            <div class="card-container">
        <div class="card">
          <img class="img-content" src={item.image} style={{ objectFit: "cover" }}></img>
          <div class="content">
            <p class="heading">{item.name}</p>
          </div>
        </div>
        </div>)
        })}
        </div>
     </ScrollAnimation>
        </div>
        <ScrollAnimation animateIn="fadeIn" >

        <div className="home-contact4">
          <Contact4></Contact4>
        </div>
        </ScrollAnimation>
      </div>
    </>
  );
}
