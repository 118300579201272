import React, { useState } from "react";
import Logo from "../images/RITESH FLEX LOOG.png";
import { useHistory } from "react-router-dom";
import "./navbar4.css";

const Navbar4 = (props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useHistory();

  // Function to toggle mobile menu
  const toggleMobileMenu = () => {
    console.log("toggleMobileMenu");
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="navbar4-container">
      <header data-thq="thq-navbar" className="navbar4-navbar-interactive">
        <img
          alt={"Ritesh Flex Printing"}
          src={Logo}
          className="navbar4-image1"
        />
        <div data-thq="thq-navbar-nav" className="navbar4-desktop-menu">
          <nav className="navbar4-links">
            <span
              className="thq-link thq-body-small"
              onClick={() => navigate.push("/")}
            >
              HOME
            </span>
            <span
              className="thq-link thq-body-small"
              onClick={() => navigate.push("/products")}
            >
              PRODUCTS
            </span>
            <span
              className="thq-link thq-body-small"
              onClick={() => navigate.push("/services")}
            >
              SERVICES
            </span>
            <span
              className="thq-link thq-body-small"
              onClick={() => navigate.push("/about-us")}
            >
              ABOUT US
            </span>
            <span
              className="thq-link thq-body-small"
              onClick={() => navigate.push("/contact-us")}
            >
              CONTACT US
            </span>
          </nav>
        </div>
        <div
          data-thq="thq-burger-menu"
          className="navbar4-burger-menu"
          onClick={toggleMobileMenu}
        >
          <svg viewBox="0 0 1024 1024" className="navbar4-icon">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>

        <div
          className={`navbar4-mobile-menu ${isMobileMenuOpen ? "" : "hidden"}`}
        >
          <div className="navbar4-nav">
            <div className="navbar4-top">
              <img alt={"Logo"} src={Logo} className="navbar4-logo" />
              <div
                data-thq="thq-close-menu"
                className="navbar4-close-menu"
                onClick={toggleMobileMenu}
              >
                <svg viewBox="0 0 1024 1024" className="navbar4-icon2">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="navbar4-links1">
              <span
                className="thq-link thq-body-small"
                onClick={() => navigate.push("/")}
              >
                HOME
              </span>
              <span
                className="thq-link thq-body-small"
                onClick={() => navigate.push("/products")}
              >
                PRODUCTS
              </span>
              <span
                className="thq-link thq-body-small"
                onClick={() => navigate.push("/services")}
              >
                SERVICES
              </span>
              <span
                className="thq-link thq-body-small"
                onClick={() => navigate.push("/about-us")}
              >
                ABOUT US
              </span>
              <span
                className="thq-link thq-body-small"
                onClick={() => navigate.push("/contact-us")}
              >
                CONTACT US
              </span>
            </nav>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navbar4;
