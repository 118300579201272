import React from "react";

import Navbar4 from "../components/navbar4";

export default function Contact() {
  return (
    <>
      <div className="home-navbar1">
        <Navbar4></Navbar4>
      </div>

      <section class="text-gray-600 body-font relative">
        <div class="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
          <div class="animate-fade-up lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
            <iframe
              width="100%"
              height="100%"
              class="absolute inset-0"
              frameborder="0"
              title="map"
              marginheight="0"
              marginwidth="0"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.5091379645883!2d84.98713008740017!3d25.21977102343246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f2b57098d72a07%3A0x75a874bc800b1ef4!2sRITESH%20FLEX%20PRINTING!5e0!3m2!1sen!2sin!4v1715532979265!5m2!1sen!2sin"
            ></iframe>

            <div class="bg-white relative flex flex-wrap py-6 rounded shadow-md ">
              <div class="lg:w-1/2 px-6">
                <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  ADDRESS
                </h2>
                <p class="mt-1">
                  P.G Road, (Near Indian Bank/V-Mart) Jehanabad - 804408
                </p>
              </div>
              <div class="lg:w-1/2 px-6 mt-4 lg:mt-0">
                <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  EMAIL
                </h2>

                <a
                  href="mailto:riteshflexprinting@gmail.com"
                  class="text-orange-500 leading-relaxed"
                >
                  riteshflexprinting@gmail.com
                </a>

                <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                  PHONE
                </h2>
                <p class="leading-relaxed"> 8264100981, 9113315571</p>
              </div>
            </div>
          </div>
          <div class="animate-fade-left lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0 p-5 rounded-lg">
            <h2 class="text-gray-900  mb-1 font-medium title-font text-2xl">
              Let's get in touch
            </h2>
            <p class="leading-relaxed mb-5 text-gray-600">
              Get in touch with us today to elevate your brand's visibility
              across Bihar.
            </p>
            <form action="https://formsubmit.co/riteshflexprinting@gmail.com" method="POST">
              
            <div class="relative mb-4">
              <label for="name" class="leading-7 text-sm text-gray-600">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                required
                class="w-full bg-white rounded border border-gray-300 focus:border-orange-500 focus:ring-2 focus:ring-yellow-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
            </div>
            <div class="relative mb-4">
              <label for="email" class="leading-7 text-sm text-gray-600">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                required
                class="w-full bg-white rounded border border-gray-300 focus:border-orange-500 focus:ring-2 focus:ring-yellow-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
            </div>
            <div class="relative mb-4">
              <label for="message" class="leading-7 text-sm text-gray-600">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                class="w-full bg-white rounded border border-gray-300 focus:border-orange-500 focus:ring-2 focus:ring-yellow-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                ></textarea>
            </div>
            <button type="submit" class="text-white bg-orange-500 border-0 py-2 px-6 focus:outline-none hover:bg-yellow-600 rounded text-lg">
              Send Message
            </button>
                </form>
          </div>
        </div>
      </section>
    </>
  );
}
