import Navbar4 from "../components/navbar4";
import Contact4 from "../components/contact4";
import { useHistory } from "react-router-dom";
import ScrollAnimation from 'react-animate-on-scroll';
export default function About() {
  const navigate = useHistory();
  return (
    <div className="flex flex-col items-center">
      <div className="home-navbar1">
        <Navbar4></Navbar4>
      </div>

      <section className="">
        <div class="max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16 mb-10 animate-flip-up">
          <div class="grid grid-cols-1 gap-y-8 lg:grid-cols-2 lg:items-center lg:gap-x-16">
            <div class="mx-auto max-w-lg text-center lg:mx-0 ltr:lg:text-left rtl:lg:text-right">
              <h2 class="text-3xl font-bold sm:text-4xl">About Us</h2>

              <p class="mt-4 text-gray-600">
                Ritesh Flex Printing is one of the top Outdoor Advertising
                Agencies in Jehanabad (Bihar). Ritesh Flex Printing provides a
                direct line of communication to your existing and prospective
                customers about your products or services. It is booming
                industry today. We combine great style and compelling content
                with state-of-the-art technology. We do this by sticking to the
                basic and clear ideas and innovative design. We work closely
                with our clients at very stage to deliver exciting and lasting
                campaigns for all strategies and budgets. We also strive to make
                our client's brand memorable. Our ad campaigns covers all
                categories of target commuters and be the first to get their
                hands on your brand.
              </p>

              <button className=" mt-8 thq-button-outline hero3-button1">
                <span
                  className="thq-body-small"
                  onClick={() => {
                    navigate.push("/contact-us");
                  }}
                >
                  Contact Us
                </span>
              </button>
            </div>

            <div class="grid grid-cols-2 gap-4 sm:grid-cols-3">
              <a
                class="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                href="#"
              >
                <span class="inline-block rounded-lg bg-gray-50 p-3">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="contact4-icon4 thq-icon-medium"
                    style={{ height: 25, width: 25 }}
                  >
                    <path d="M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z"></path>
                  </svg>
                </span>

                <h2 class="mt-2 font-bold">Masaurhi</h2>
              </a>

              <a
                class="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                href="#"
              >
                <span class="inline-block rounded-lg bg-gray-50 p-3">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="contact4-icon4 thq-icon-medium"
                    style={{ height: 25, width: 25 }}
                  >
                    <path d="M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z"></path>
                  </svg>
                </span>

                <h2 class="mt-2 font-bold">Jehanabad</h2>
              </a>

              <a
                class="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                href="#"
              >
                <span class="inline-block rounded-lg bg-gray-50 p-3">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="contact4-icon4 thq-icon-medium"
                    style={{ height: 25, width: 25 }}
                  >
                    <path d="M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z"></path>
                  </svg>
                </span>

                <h2 class="mt-2 font-bold">Tehta</h2>
              </a>

              <a
                class="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                href="#"
              >
                <span class="inline-block rounded-lg bg-gray-50 p-3">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="contact4-icon4 thq-icon-medium"
                    style={{ height: 25, width: 25 }}
                  >
                    <path d="M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z"></path>
                  </svg>
                </span>

                <h2 class="mt-2 font-bold">Makhdumpur</h2>
              </a>

              <a
                class="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                href="#"
              >
                <span class="inline-block rounded-lg bg-gray-50 p-3">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="contact4-icon4 thq-icon-medium"
                    style={{ height: 25, width: 25 }}
                  >
                    <path d="M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z"></path>
                  </svg>
                </span>

                <h2 class="mt-2 font-bold">Gaya</h2>
              </a>

              <a
                class="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                href="#"
              >
                <span class="inline-block rounded-lg bg-gray-50 p-3">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="contact4-icon4 thq-icon-medium"
                    style={{ height: 25, width: 25 }}
                  >
                    <path d="M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z"></path>
                  </svg>
                </span>

                <h2 class="mt-2 font-bold">All over Bihar</h2>
              </a>
            </div>
          </div>
        </div>
      </section>
      <div class="space-y-4  w-[80%] mb-20 animate-fade-up">
        <details class="group [&_summary::-webkit-details-marker]:hidden " open>
          <summary class="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-200 p-4 text-gray-900">
            <h2 class="font-medium">
              Why should you choos Ritesh Flex Printing for OOH Advertising ?
            </h2>

            <svg
              class="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </summary>

          <p class="mt-4 px-4 leading-relaxed text-gray-700">
            We brings you leading-edge technology and advanced outdoor
            advertising format. We brings to you, a one-stop solution to all
            your advertising needs. Railway Advertising, Bus Advertising, City
            and Highway Hoardings, Mall Advertising, Airport Advertising etc. We
            covers all category of target commuters. Our campaigns reach to
            hard-to-reach commuters like executives, businessman, housewife,
            affluent residents and members of targeted communities besides
            people belongs to all strata and classs.
          </p>
        </details>

        <details class="group [&_summary::-webkit-details-marker]:hidden " open>
          <summary class="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-200 p-4 text-gray-900">
            <h2 class="font-medium">
              What areas in Bihar does your advertising service cover, and how
              do we ensure maximum impact for clients?
            </h2>

            <svg
              class="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </summary>

          <p class="mt-4 px-4 leading-relaxed text-gray-700">
            We provide extensive coverage in Jehanabad, Tehta, Makhdumpur, Bela,
            Gaya, Nadaul, Masaurhi, Patna, and throughout Bihar at highly
            affordable prices. Our commitment to delivering quality service
            means we collaborate extensively with our clients for maximum
            impact. We are passionate about showcasing your brand in the best
            possible way, enabling you to reach potential customers across the
            entire Jehanabad, Tehta, Makhdumpur, Bela, Gaya, Nadaul, Masaurhi,
            Patna, and Bihar city areas. Our diverse range of available sites
            allows you to target specific markets, thereby minimizing
            advertising wastage.
          </p>
        </details>
      </div>
      <Contact4 />
    </div>
  );
}
