import React from "react";
import VMart from "../images/vmart.jpeg";
import DalmiyaBazar from "../images/DalmiyaBazar.jpeg";
import TVS from "../images/tvs.webp";
import Honda from "../images/honda.png";
import OneIndia from "../images/1India.png";
import Bajaj from "../images/bajaj.png";
import "./logos1.css";

const Logos1 = () => {
  return (
    <div className="logos1-container thq-section-padding">
      <div className="logos1-max-width thq-section-max-width">
        <h2 className="logos1-text thq-heading-2">
          Trusted by 50+ companies all across Bihar
        </h2>
        <div className="thq-grid-6">
          <img
            alt={"VMart"}
            src={VMart}
            className="logos1-logo1 thq-img-ratio-16-9"
          />
          <img
            alt={"Dalmiya Bazar"}
            src={DalmiyaBazar}
            className="logos1-logo2 thq-img-ratio-16-9"
          />
          <img
            alt={"TVS"}
            src={TVS}
            className="logos1-logo3 thq-img-ratio-16-9"
          />
          <img
            alt={"Honda"}
            src={Honda}
            className="logos1-logo4 thq-img-ratio-16-9"
          />
          <img
            alt={"One India"}
            src={OneIndia}
            className="logos1-logo5 thq-img-ratio-16-9"
          />
          <img
            alt={"Bajaj"}
            src={Bajaj}
            className="logos1-logo6 thq-img-ratio-16-9"
          />
        </div>
      </div>
    </div>
  );
};

export default Logos1;
