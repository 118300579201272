import React from "react";

import "./feature-card.css";

const FeatureCard = (props) => {
  return (
    <div className="feature-card-card w-full ">
      <div>{props.children}</div>
      <h4 className="feature-card-text Heading4 text-xl font-bold">
        {props.title}
      </h4>
    </div>
  );
};

export default FeatureCard;
