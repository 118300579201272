import React from "react";

import { Helmet } from "react-helmet";
import ScrollAnimation from 'react-animate-on-scroll';
import Navbar4 from "../components/navbar4";
import Hero3 from "../components/hero3";
import Logos1 from "../components/logos1";
import CTA1 from "../components/cta1";
import Contact4 from "../components/contact4";
import "./home.css";
import "animate.css/animate.compat.css"

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Ritesh Flex Printing</title>
        <meta property="og:title" content="Ritesh Flex Printing" />
      </Helmet>
      <div className="home-navbar1">
        <Navbar4></Navbar4>
      </div>
      <div className="home-hero2">
        <Hero3></Hero3>
      </div>
      <ScrollAnimation animateIn="fadeIn">

      <div className="home-logos3">
        <Logos1></Logos1>
      </div>

      <div className="home-gallery4"></div>
   
      <div className="home-features5"></div>
      <div className="home-features6"></div>
      <div className="home-features7"></div>
      </ScrollAnimation>
      <ScrollAnimation animateIn="zoomIn" animateOut="zoomOut">
      <div className="home-cta8">
   

        <CTA1></CTA1>
      </div>
        </ScrollAnimation>
      <div className="home-faq9"></div>
      <div className="home-contact10">
        <Contact4></Contact4>
      </div>
      <div className="home-footer11"></div>
    </div>
  );
};

export default Home;
