import React from "react";
import "./hero3.css";
import { useHistory } from "react-router-dom";
const Hero3 = (props) => {
  const navigate = useHistory();
  return (
    <div
      className="hero3-header9"
      style={{
        backgroundImage: `url(https://images.unsplash.com/photo-1522202176988-66273c2fd55f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNDg5MjAzMnw&ixlib=rb-4.0.3&q=80&w=1080)`,
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
      }}
    >
      <div className="hero3-content thq-section-padding bg-white bg-opacity-50 backdrop-blur-md h-[90vh]">
        <div className="hero3-max-width thq-section-max-width thq-flex-row ">
          <div className="hero3-column sm:items-start items-center">
            <h1 className="thq-heading-1 hero3-text">Welcome to</h1>
            <h1 className="thq-heading-1 hero3-text">Ritesh Flex Printing</h1>
          </div>

          <div className="hero3-column1">
            <p className="thq-body-large hero3-text1">
              Capture attention, amplify visibility: Transform your brand with
              Ritesh Flex Printing's dynamic outdoor advertising solutions.
            </p>
            <div className="hero3-actions">
              <button
                className="thq-button-filled hero3-button"
                onClick={() => navigate.push("/services")}
              >
                <span className="thq-body-small">Explore Our Services</span>
              </button>
              <button
                className="thq-button-outline hero3-button1"
                onClick={() => navigate.push("/contact-us")}
              >
                <span className="thq-body-small">Contact Us</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero3;
