import React from "react";
import FlexPrinting from "../images/FlexPrinting.webp";
import PropTypes from "prop-types";
import Advertising from "../images/Advertising.jpg";
import "./cta1.css";
import { useHistory } from "react-router-dom";
const CTA1 = (props) => {
  const navigate = useHistory();
  return (
    <div className="cta1-container thq-section-padding">
      <div className="features17-layout349 thq-section-padding">
        <div className="features17-max-width thq-section-max-width">
          <div className="features17-image-container">
            <img
              alt="Flex Printing"
              src={FlexPrinting}
              className="thq-img-ratio-16-9"
            />
          </div>
          <div className="features17-content">
            <div className="features17-section-title">
              <span className="thq-body-small">
                Flex Your Brand's Potential with Vibrant Prints
              </span>
              <div className="features17-content1">
                <h2 className="thq-heading-2">Flex Printing</h2>
                <p className="thq-body-large">
                  Elevate your brand with our comprehensive printing solutions:
                  banners, light boards, vinyl prints, visiting cards,
                  handbills, cup and t-shirt prints, and ID cards—all under one
                  roof!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="features18-layout349 thq-section-padding">
        <div className="features18-max-width thq-section-max-width">
          <div className="features18-content">
            <div className="features18-section-title">
              <span className="thq-body-small">
                Empower Your Brand's Reach with Dynamic Advertising Solutions
              </span>
              <div className="features18-content1">
                <h2 className="thq-heading-2">Advertising Services</h2>
                <p className="thq-body-large">
                  Maximize your brand's impact with our advertising services:
                  hoardings, visiting cards, light boards, prachar
                  (advertisement), digital prints, and handbills.
                </p>
              </div>
            </div>
          </div>
          <div className="features18-image-container">
            <img
              alt={Advertising}
              src={Advertising}
              className="thq-img-ratio-16-9"
            />
          </div>
        </div>
      </div>
      <div className="cta1-max-width thq-section-max-width">
        <div className="cta1-content">
          <h2 className="cta1-heading1 thq-heading-2">
            Ready to boost your brand?
          </h2>
          <p className="cta1-content1 thq-body-large">
            Contact us today for a free quote on all your printing and marketing
            needs.
          </p>
          <div className="cta1-actions">
            <button
              className="thq-button-filled cta1-button"
              onClick={() => navigate.push("/contact-us")}
            >
              <span className="cta1-action1 thq-body-small">Get a Quote</span>
            </button>
            <button
              className="thq-button-outline cta1-button1"
              onClick={() => navigate.push("/services")}
            >
              <span className="cta1-action2 thq-body-small">
                View Our Services
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTA1;
