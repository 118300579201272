import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import "./style.css";
import Home from "./views/home";
import NotFound from "./views/not-found";
import Products from "./views/Products";
import Contact from "./views/Contact";
import Services from "./views/Services";
import About from "./views/About";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={Home} exact path="/" />
        <Route component={Products} path={"/products"} />
        <Route component={Contact} path={"/contact-us"} />
        <Route component={Services} path={"/services"} />
        <Route component={About} path={"/about-us"} />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));
